// import { ChatEngine } from "react-chat-engine";
// import "./App.css";
// import LoginForm from "./components/LoginForm";

// import ChatFeed from "./components/ChatFeed";

// const projectID = "f3591b15-d550-4af9-b52f-2899f0ec2574"

// const App = () => {
//     if(!localStorage.getItem('username'))   return <LoginForm />

//     return (
//         <ChatEngine
//             height="100vh"
//             projectID={projectID}
//             userName={localStorage.getItem('userName')}
//             userSecret={localStorage.getItem('password')}
//             renderChatFeed={(chatAppProps) => <ChatFeed {...chatAppProps} />}
//             onNewMessage={() => new Audio('https://chat-engine-assets.s3.amazonaws.com/click.mp3').play()}
//         />
//     );
// }

// export default App;

import { ChatEngine } from "react-chat-engine";
import "./App.css";
import LoginForm from "./components/LoginForm";

import ChatFeed from "./components/ChatFeed";

const projectID = "f3591b15-d550-4af9-b52f-2899f0ec2574"

const App = () => {
    if(!localStorage.getItem('username'))   return <LoginForm />

    return (
        <ChatEngine
            height="100vh"
            projectID={projectID}
            userName={localStorage.getItem('username')}
            userSecret={localStorage.getItem('password')}
            // renderChatFeed={(chatAppProps) => <ChatFeed {...chatAppProps} />}
            onNewMessage={() => new Audio('https://chat-engine-assets.s3.amazonaws.com/click.mp3').play()}
        />
    );
}

export default App;